import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import GoogleMapComponentWithMarker from "../components/the-map.js"

import theMap from "../images/themap.gif"
import "../components/style/map.css"

const TheMap = () => (
  <Layout>
    <SEO title="The Map" />
    <h1 style={{ marginTop: `2rem`, }}>The Map</h1>
    <p>So you want to get to the party?</p>
    <p>Just follow this map!</p>
    <div className="map-top-container">
      <div className="map-container">
        <h3>The Map</h3>
        <div className="map">
          <GoogleMapComponentWithMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAC6mgpwgzhBD7XlPLMcREqa_5PRByMp1M"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
      <div className="directions-container">
        <h3>Instructions</h3>
        <p>
        Getting to 830 N. Onondaga rd. Holt, MI 48842 can be tricky.
        Don't feel too bad if you get a little lost along the way, we've had to
        stand at the end of the driveway to flag people down countless times!
        </p>
        <p>
        The first key landmark you'll want to look for is the yellow house. This
        house is right at the end of the grvel driveway (but it is <strong>not </strong>
        our house). Turn into the driveway just south of the yellow house.
        </p>
        <p>
        Now if you're on the right driveway you should go between open fields and
        come to a fork in the road, if you go right you'll end up at the neighbors...
        don't go right. Instead turn left!
        </p>
        <p>
        Now that you've turned left you should be heading into the woods. Don't worry,
        you're on the right path! Keep going, you'll get here eventually!
        </p>
        <p><i>
        Note: if you follow these instructions and end up at the wrong place
        ...well then you didn't follow the instructions, did you?
        </i></p>
      </div>
    </div>
    <img src={theMap} alt="Spongebob the map!" />
  </Layout>
)

export default TheMap
